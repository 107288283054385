export const breadcrumbList = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '营销列表',
    path: '/main/marketing/list'
  },
  {
    name: '发券宝',
    path: ''
  }
]
