export const contentTableConfig = {
  propList: [
    {
      prop: 'name',
      label: '活动名称',
      slotName: 'name'
    },
    { prop: 'sendTime', label: '发放时间', slotName: 'sendTime'},
    { prop: 'dpName', label: '归属门店', slotName: 'dpName'},
    {
      prop: 'couponName',
      label: '券名称',
      slotName: 'couponName'
    },
    {
      prop: 'count',
      label: '发放数量',
      slotName: 'count'
    },
    // { label: '操作', minWidth: '200', slotName: 'handler', }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
