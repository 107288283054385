<template>
  <div class="top-content">
    <el-button @click="handleAddCouponClick" type="primary" v-has="'give:add'">新建定向发券</el-button>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const router = useRouter()
    const handleAddCouponClick = () => {
      router.push({
        path: "/main/coupon/maketingAddCoupon"
      })
    }

    return {
      handleAddCouponClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  margin-bottom: 20px;
}
</style>




