<template>
  <div class="coupon-list">
    <page-content
      :dataList="dataList"
      @selectionChange="selectionChange"
      :contentTableConfig="contentTableConfig"
      :isButtonStyle="true"
      @tableCellClick="handleTableCellClick"
    >
    </page-content>
    <div class="el-pagination">
      <el-pagination
        v-model:currentPage="queryInfo.current"
        v-model:page-size="queryInfo.size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
import { getOpenCouponLlist } from '@/service/main/goods'

export default defineComponent({
  props: {

  },
  components: {
    PageContent
  },
  setup() {

    const selectionChange = () => {

    }

    const handleTableCellClick = () => {

    }

    const queryInfo = reactive({
      current: 1,
      size: 10,
      keyword: ""
    })

    const handleSizeChange = pagesize => {
      queryInfo.size = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      queryInfo.current = page
      initPage()
    }

    const dataList = ref()
    const dataCount = ref(0)

    const initPage = async () => {
      const res = await getOpenCouponLlist({ ...queryInfo })
      dataList.value = res.data.list
      dataCount.value = Number(res.data.total)
    }
    initPage()

    return {
      dataList,
      handleSizeChange,
      handleCurrentChange,
      dataCount,
      queryInfo,
      contentTableConfig,
      selectionChange,
      handleTableCellClick
    }

  }
})
</script>

<style scoped lang="less"></style>
